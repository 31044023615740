import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { RoleGuardService } from '~app/core/core.module';
import { UserEditPageComponent } from './user-edit/user-edit-page.component';
import { UsersComponent } from './users.component';

const routes: Routes = [
  {
    path: '',
    component: UsersComponent,
    canActivate: mapToCanActivate([AuthGuard, RoleGuardService]),
    data: {
      expectedRoles: ['Manager', 'Administrator', 'SuperUser']
    }
  },
  {
    path: 'adduser',
    component: UserEditPageComponent,
    canActivate: mapToCanActivate([AuthGuard, RoleGuardService]),
    data: {
      expectedRoles: ['Manager', 'Administrator', 'SuperUser'],
      addUser: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {}
