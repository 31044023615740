import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { NbSecurityModule } from '@nebular/security';
import { FilterSelectModule } from '~app/core/utils/filter-select/filter-select.module';
import { MAT_DAYJS_DATETIME_FORMATS } from '~app/core/utils/material-dayjs-adapter/dayjs-datetime-formats';
import { SharedModule } from '~app/shared/shared.module';
import { ConfirmLicenseComponent } from './generate-license/confirm-license/confirm-license.component';
import { GenerateLicenseDialogComponent } from './generate-license/generate-license-dialog.component';
import { GenerateLicensePageComponent } from './generate-license/generate-license-page.component';
import { GenerateLicenseComponent } from './generate-license/generate-license.component';
import { MetaDataValuesComponent } from './generate-license/meta-data-values/meta-data-values.component';
import { LicenseActionsComponent } from './license-actions.component';
import { LicenseAppNameComponent } from './license-app-name.component';
import { LicenseDetailsDialogComponent } from './license-details-dialog.component';
import { LicenseDetailsComponent } from './license-details/license-details.component';
import { LicenseHistoryDialogComponent } from './license-history-dialog.component';
import { LicenseHistoryComponent } from './license-history/license-history.component';
import { LicensesRoutingModule } from './licenses-routing.module';
import { LicensesComponent } from './licenses.component';

@NgModule({
  declarations: [
    ConfirmLicenseComponent,
    GenerateLicenseComponent,
    GenerateLicenseDialogComponent,
    GenerateLicensePageComponent,
    LicensesComponent,
    LicenseAppNameComponent,
    LicenseActionsComponent,
    LicenseDetailsComponent,
    LicenseDetailsDialogComponent,
    LicenseHistoryComponent,
    LicenseHistoryDialogComponent,
    MetaDataValuesComponent
  ],
  imports: [
    LicensesRoutingModule,
    CommonModule,
    FilterSelectModule,
    FormsModule,
    NbSecurityModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [LicensesComponent],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATETIME_FORMATS }
  ]
})
export class LicensesModule {}
