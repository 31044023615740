import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ValidatorService } from './validator.service';

@Injectable()
export class DefaultValidatorService implements ValidatorService {
  getRowValidator(): UntypedFormGroup {
    return null;
  }
}
