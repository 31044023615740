import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from '../../../../core/data/users.service';
import { AppUserSettings } from '../../../../core/models/app-user-settings.model';
import { User } from '../../../../core/models/user.model';

@Component({
  selector: 'idl-user-app-settings',
  templateUrl: './user-app-settings.component.html',
  styleUrls: ['./user-app-settings.component.scss']
})
export class UserAppSettingsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() userId: number;
  user: User;
  form: UntypedFormGroup;

  options = ['AllEmails', 'ImportantUpdates', 'NoEmails'];

  constructor(private _usersService: UsersService) {}

  ngOnInit() {
    const group: any = {};

    this._usersService
      .get(this.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.user = user;

        this.user.applicationSettings.forEach(setting => {
          group[setting.applicationId] = new UntypedFormControl(
            setting.mailPolicyName || '',
            Validators.required
          );
        });
        this.form = new UntypedFormGroup(group);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    const keys = Object.keys(this.form.value);
    const aus: AppUserSettings[] = [];
    keys.forEach(key => {
      aus.push(
        new AppUserSettings(+key, undefined, this.userId, this.form.value[key])
      );
    });
    this._usersService
      .editAppSettings(this.userId, { appUserSettings: aus })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        // console.log(result);
      });
  }
}
