import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationsService } from '~app/core/data/applications.service';
import { Application } from '~app/core/models/application.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  appId: number;
  private _applicationSubject$ = new BehaviorSubject(null);
  private _testSubject$ = new Subject();
  complete = false;

  constructor(private _applicationsService: ApplicationsService) {
    this._testSubject$
      .pipe(
        switchMap(() => this._applicationsService.get(this.appId)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(app => {
        if (app) {
          this._applicationSubject$.next(app);
        }
      });
  }

  async setAppId(id: number) {
    if (id !== this.appId) {
      this.appId = id;
      this._testSubject$.next();
    }
  }

  getApp(id: number): Observable<Application> {
    this.setAppId(id);

    return this._applicationSubject$.asObservable();
  }

  reloadApp() {
    this._testSubject$.next();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
