import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { License } from '~app/core/models/license.model';
import { Application } from '../../../core/models/application.model';

export interface DialogData {
  application: Application;
  license: License;
}

@Component({
  selector: 'idl-version-dialog',
  template: `
    <h2 mat-dialog-title>{{ application.name }} Versions</h2>
    <mat-dialog-content class="mat-typography">
      <idl-versions-content
        [application]="application"
        [license]="license"
      ></idl-versions-content>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
  `,
  styles: [
    '.mat-mdc-dialog-content { height: 100%!important; width: 100%!important;}'
  ]
})
export class VersionDialogComponent {
  @Input() application: Application;
  @Input() license: License;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {
    if (dialogData) {
      this.application = dialogData.application;
      this.license = dialogData.license;
    }
  }
}
