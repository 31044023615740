import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Log,
  LogCounters,
  LogCountersResponse,
  LogsResponse
} from '../models/logs.model';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  /**
   * Base url of logs service
   */
  private baseUrl = 'api/logs';

  constructor(private http: HttpClient) {}

  /**
   * Gets all logs
   * @returns Array of Log objects
   */
  get(
    offset = 0,
    limit = 0,
    fromDate?: Date,
    toDate?: Date
  ): Observable<Log[]> {
    const url = `${this.baseUrl}/`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: LogsResponse) => response.data.logs.map(Log.adapt))
      // catchError(this.handleError)
    );
  }

  userdownloads(userId: number): Observable<Log[]> {
    const url = `${this.baseUrl}/users/${userId}/downloads`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: LogsResponse) => response.data.logs.map(Log.adapt))
      // catchError(this.handleError)
    );
  }

  userlogins(userId: number): Observable<Log[]> {
    const url = `${this.baseUrl}/users/${userId}/logins`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: LogsResponse) => response.data.logs.map(Log.adapt))
      // catchError(this.handleError)
    );
  }

  status(): Observable<LogCounters> {
    const url = `${this.baseUrl}/status`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: LogCountersResponse) => LogCounters.adapt(response.data))
      // catchError(this.handleError)
    );
  }
}
