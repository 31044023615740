import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NotificationService } from '~core/core.module';
import { LicenseService } from '~core/data/license.service';
import { ChangeLicenseRequest, License } from '~core/models/license.model';

@Component({
  selector: 'idl-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss']
})
export class LicenseDetailsComponent implements OnInit {
  license: License;
  additionalEmails: string[];
  detailsForm: UntypedFormGroup;

  @Input() licenseId: number;
  constructor(
    private _fb: UntypedFormBuilder,
    private licenseService: LicenseService,
    private readonly _notificationService: NotificationService
  ) {
    this.detailsForm = _fb.group({
      licenseGuid: [],
      licenseHolderName: [],
      applicationName: [],
      type: [],
      expirydate: [],
      emails: new UntypedFormArray([]),
      comments: [],
      creationdate: [],
      createdByUserName: [],
      metadataSetName: [],
      licenseData: []
    });
  }

  ngOnInit() {
    if (this.licenseId) {
      this.licenseService.get(this.licenseId).subscribe(license => {
        this.license = license;
        this.additionalEmails =
          license.additionalEmails?.length > 0
            ? license.additionalEmails?.split(',')
            : [];

        this.detailsForm.patchValue({
          licenseGuid: license.licenseGuid,
          licenseHolderName: license.licenseHolderName,
          applicationName: license.applicationName,
          type: license.type,
          expirydate: license.expirydate,
          comments: license.comments,
          creationdate: license.creationdate,
          createdByUserName: license.createdByUserName,
          metadataSetName: license.metadataSetName,
          licenseData: license.licenseData
        });

        this.additionalEmails.forEach(e => {
          const form = new UntypedFormControl(e, Validators.email);
          (<UntypedFormArray>this.detailsForm.controls['emails']).push(form);
        });
      });
    }
  }

  AddAdditionalEmail() {
    const form = new UntypedFormControl('', Validators.email);
    (<UntypedFormArray>this.detailsForm.controls['emails']).push(form);
  }

  DeleteAdditionalEmail(index: number) {
    (<UntypedFormArray>this.detailsForm.controls['emails']).removeAt(index);
  }

  updateComments() {
    const changeRequest = new ChangeLicenseRequest(
      this.detailsForm.controls['comments'].value,
      null
    );
    this.licenseService
      .change(this.licenseId, changeRequest)
      .subscribe(license => {
        this._notificationService.success('License updated.');
      });
  }

  updateEmails() {
    const additionalEmails = (<UntypedFormArray>(
      this.detailsForm.controls.emails
    )).controls
      .map(c => c.value)
      .join();

    const changeRequest = new ChangeLicenseRequest(null, additionalEmails);
    this.licenseService
      .change(this.licenseId, changeRequest)
      .subscribe(license => {
        this._notificationService.success('License updated.');
      });
  }

  copyInput(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
}
