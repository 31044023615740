import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressDialogComponent } from './progress-dialog.component';

@NgModule({
  imports: [CommonModule, MatProgressBarModule],
  exports: [ProgressDialogComponent],
  declarations: [ProgressDialogComponent]
})
export class ProgressDialogModule {}
