import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NbRoleProvider } from '@nebular/security';
import * as dayjs from 'dayjs';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { NotificationService } from '~app/core/core.module';
import { ApplicationsService } from '~app/core/data/applications.service';
import { ApplicationLicenseService } from '~core/data/application-license.service';
import { LicenseService } from '~core/data/license.service';
import { MetadataDefinitionService } from '~core/data/metadata-definition.service';
import { MetadataSetService } from '~core/data/metadata-set.service';
import { MetadataService } from '~core/data/metadata.service';
import { UsersService } from '~core/data/users.service';
import { Application } from '~core/models/application.model';
import {
  CreateLicenseRequest,
  License,
  LicenseType,
  LicenseTypes
} from '~core/models/license.model';
import { MetadataSet } from '~core/models/metadata-set.model';
import {
  LicenseGenMetadata,
  MetadataOverride
} from '~core/models/metadata.model';
import { Roles } from '~core/models/roles.model';
import { UserSummary } from '~core/models/user-summary.model';
import { EnumEx } from '~core/utils/enum-ex.service';
import { TableDataSource } from '~core/utils/material-table/table-data-source';
import { ConfirmLicenseComponent } from './confirm-license/confirm-license.component';

@Component({
  selector: 'idl-generate-license',
  templateUrl: './generate-license.component.html',
  styleUrls: ['./generate-license.component.scss']
})
export class GenerateLicenseComponent implements OnInit, OnDestroy {
  generateLicenseForm = this._fb.group({
    selectedUser: [null, Validators.required],
    selectedUserFilter: [''],
    selectedApplication: [null, Validators.required],
    licenseType: [null, Validators.required],
    expiryDate: [null],
    selectedSet: [null],
    additionalEmails: new UntypedFormArray([]),
    setDescription: [''],
    comments: ['']
  });

  @Input() applicationId: number;
  @Input() license: License;

  metadataSets$: Observable<MetadataSet[]>;
  selectedSet: MetadataSet;
  users: UserSummary[];
  selectedUser: UserSummary = null;

  /** list of users filtered by search keyword */
  public filteredUsers: ReplaySubject<UserSummary[]> = new ReplaySubject<
    UserSummary[]
  >(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  applications: Application[];
  activeApplicationID: number;
  activeMetaData: LicenseGenMetadata[] = [];
  overrideMetaData: MetadataOverride[] = [];
  expiryDate: dayjs.Dayjs;
  comments: string;
  licenseType: string;
  types: LicenseType[];
  submitted: Boolean;

  displayedColumns: string[] = ['definitionName', 'newval', 'actionsColumn'];

  // dataSource = new MatTableDataSource<LicenseGenMetadata>();

  @Output() metadataChange = new EventEmitter<LicenseGenMetadata[]>();

  dataSource: TableDataSource<LicenseGenMetadata>;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Input() saveEvent: Observable<void>;

  constructor(
    private _appLicenseService: ApplicationLicenseService,
    private _applicationService: ApplicationsService,
    private _dateAdaptor: DateAdapter<any>,
    private _dialog: MatDialog,
    private _fb: UntypedFormBuilder,
    private _licenseService: LicenseService,
    private metadataSetService: MetadataSetService,
    private metadataService: MetadataService,
    private metadataDefinitionService: MetadataDefinitionService,
    private readonly _notificationService: NotificationService,
    private _roleProvider: NbRoleProvider,
    private usersService: UsersService,
    private location: Location
  ) {
    _dateAdaptor.setLocale('en-gb');

    this.dataSource = new TableDataSource(
      this.activeMetaData,
      LicenseGenMetadata
    );

    // listen for search field value changes
    this.generateLicenseForm.controls.selectedUserFilter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers(
          this.generateLicenseForm.controls.selectedUserFilter.value
        );
      });

    this.types = this.getLicenseTypes();

    this.submitted = false;
  }

  getLicenseTypes() {
    const types: LicenseType[] = [];

    // Get name-value pairs from ProductTypeEnum
    const typeEnumList = EnumEx.getNamesAndValues(LicenseTypes);

    // Convert name-value pairs to ProductType[]
    typeEnumList.forEach(pair => {
      const prodType = { id: pair.value, name: pair.name };
      types.push(prodType);
    });

    return types;
  }

  getDefaultMetadata(applicationId) {
    this.metadataDefinitionService.listall(applicationId).subscribe(data => {
      data = data.sort((a, b) =>
        a.keyName > b.keyName ? 1 : b.keyName > a.keyName ? -1 : 0
      );
      this.activeMetaData.length = 0;
      for (const mdd of data) {
        let override = 0;
        let newvalue = '';
        const index = this.overrideMetaData.findIndex(
          o => o.metadataDefinitionId === mdd.metadataDefinitionId
        );
        if (index >= 0) {
          override = 1;
          newvalue = this.overrideMetaData[index].value;
        }
        this.activeMetaData.push(
          new LicenseGenMetadata(
            0,
            mdd.metadataDefinitionId,
            0,
            mdd.keyName,
            mdd.keyType,
            mdd.keyDefault,
            newvalue,
            override
          )
        );
      }
      this.dataSource = new TableDataSource(
        this.activeMetaData,
        LicenseGenMetadata
      );
    });
  }

  ngOnInit() {
    this.activeApplicationID = this.applicationId;

    this.saveEvent
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.createLicense());

    this.metadataSets$ = this.metadataSetService.list(this.activeApplicationID);
    this.getDefaultMetadata(this.activeApplicationID);

    this.usersService.list(null, null, null, true, true).subscribe(data => {
      this.users = data;
      // load the initial filtered list
      this.filteredUsers.next(this.users.slice());

      if (this.license) {
        this._roleProvider
          .getRole()
          .pipe(
            take(1),
            mergeMap(roleNames => {
              let roles: string[];
              if (
                roleNames.includes(Roles[Roles.Administrator]) ||
                roleNames.includes(Roles[Roles.SuperUser])
              ) {
                roles = null;
              } else {
                roles = [Roles[Roles.Manager], Roles[Roles.Developer]];
              }

              return combineLatest([
                this.usersService.get(this.license.licenseHolderId),
                this.metadataSets$,
                this._applicationService.list(roles)
              ]);
            })
          )
          .subscribe(([user, sets, applications]) => {
            this.applications = applications;
            const metaDataSet = sets.find(
              s => s.name === this.license.metadataSetName
            );

            const application = applications.find(
              a => a.applicationId === this.activeApplicationID
            );

            const userSummary = this.users.find(u => u.userId === user.userId);

            if (metaDataSet) {
              this.generateLicenseForm.patchValue({
                selectedUser: userSummary,
                selectedApplication: application,
                licenseType: this.license.type,
                selectedSet: metaDataSet,
                setDescription: metaDataSet.comments,
                comments: this.license.comments
              });
            } else {
              this.generateLicenseForm.patchValue({
                selectedUser: userSummary,
                selectedApplication: application,
                licenseType: this.license.type,
                comments: this.license.comments
              });
            }
          });
      } else {
        this._applicationService
          .get(this.activeApplicationID)
          .pipe(takeUntil(this._onDestroy))
          .subscribe(app => {
            this.generateLicenseForm.patchValue({
              selectedApplication: app
            });
          });
      }
    });

    let now = dayjs();
    if (this.license && this.license.expirydate.getTime() >= Date.now())
      now = dayjs(this.license.expirydate);

    this.expiryDate = now.startOf('day').add(1, 'year');

    this.generateLicenseForm.patchValue({
      expiryDate: this.expiryDate
    });

    this.dataSource.datasourceSubject.subscribe(personList =>
      this.metadataChange.emit(personList)
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  AddAdditionalEmail() {
    const form = new UntypedFormControl('', Validators.email);
    (<UntypedFormArray>(
      this.generateLicenseForm.controls['additionalEmails']
    )).push(form);
  }

  DeleteAdditionalEmail(index: number) {
    (<UntypedFormArray>(
      this.generateLicenseForm.controls['additionalEmails']
    )).removeAt(index);
  }

  filterUsers(event) {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = event;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the users
    this.filteredUsers.next(
      this.users.filter(user => {
        return (
          user.fullName.toLowerCase().indexOf(search) > -1 ||
          user.email.toLowerCase().indexOf(search) > -1
        );
      })
    );
  }

  onChangeApplication(event) {
    this.activeApplicationID = event.applicationId;
    this.metadataSets$ = this.metadataSetService.list(event.applicationId);
    this.generateLicenseForm.patchValue({
      selectedSet: null
    });
  }

  onChangeSet(event: MetadataSet) {
    if (!event) {
      this.generateLicenseForm.patchValue({
        setDescription: null
      });
      this.getDefaultMetadata(this.activeApplicationID);
    } else {
      this.generateLicenseForm.patchValue({
        setDescription: event.comments
      });
      this.metadataService
        .list(this.activeApplicationID, event.metadataSetId)
        .subscribe(data => {
          data = data.sort((a, b) =>
            a.definitionName > b.definitionName
              ? 1
              : b.definitionName > a.definitionName
              ? -1
              : 0
          );
          this.activeMetaData.length = 0;
          for (const md of data) {
            let override = 0;
            let newvalue = '';
            const index = this.overrideMetaData.findIndex(
              o => o.metadataDefinitionId === md.metadataDefinitionId
            );
            if (index >= 0) {
              override = 1;
              newvalue = this.overrideMetaData[index].value;
            }
            let currentValue = md.definitionValue;
            if (md.value) currentValue = md.value;

            this.activeMetaData.push(
              new LicenseGenMetadata(
                md.metadataId,
                md.metadataDefinitionId,
                md.metadataSetId,
                md.definitionName,
                md.definitionType,
                currentValue,
                newvalue,
                override
              )
            );
          }
          this.dataSource = new TableDataSource(this.activeMetaData);
        });
    }
  }

  compareUser(c1, c2): boolean {
    return c1 && c2 ? c1.userId === c2.userId : c1 === c2;
  }

  compareSet(c1, c2): boolean {
    return c1 && c2 ? c1.metadataSetId === c2.metadataSetId : c1 === c2;
  }

  compareApplication(c1, c2): boolean {
    return c1 && c2 ? c1.applicationId === c2.applicationId : c1 === c2;
  }

  back() {
    this.location.back();
    return false;
  }

  createLicense() {
    this.submitted = true;

    const additionalEmails = (<UntypedFormArray>(
      this.generateLicenseForm.controls.additionalEmails
    )).controls
      .map(c => c.value)
      .join();

    this.activeApplicationID = this.generateLicenseForm.controls.selectedApplication.value.applicationId;
    this.selectedUser = this.generateLicenseForm.controls.selectedUser.value;
    this.licenseType = this.generateLicenseForm.controls.licenseType.value;
    this.expiryDate = this.generateLicenseForm.controls.expiryDate.value;
    this.selectedSet = this.generateLicenseForm.controls.selectedSet.value;
    this.comments = this.generateLicenseForm.controls.comments.value;

    const dialogRef = this._dialog.open(ConfirmLicenseComponent, {
      width: '99%',
      maxWidth: '600px',
      data: {
        user: this.selectedUser,
        licenseType: this.licenseType,
        expiryDate: this.expiryDate,
        selectedSet: this.selectedSet,
        comments: this.comments,
        additionalEmails: additionalEmails
      }
    });

    dialogRef.afterClosed().subscribe(name => {
      if (name) {
        let msId = 0;
        if (this.selectedSet) {
          msId = this.selectedSet.metadataSetId;
        }
        const licenseRequest = new CreateLicenseRequest(
          this.activeApplicationID,
          msId,
          this.selectedUser.userId,
          this.licenseType,
          this.expiryDate.toDate(),
          this.comments,
          this.overrideMetaData,
          null,
          additionalEmails
        );

        this._appLicenseService
          .create(this.activeApplicationID, licenseRequest)
          .subscribe(license => {
            if (this.license) {
              this._licenseService
                .attachHistory(
                  license.licenseRecordId,
                  this.license.licenseRecordId
                )
                .pipe(take(1))
                .subscribe();
            }

            this._notificationService.success('License successfully created.');

            this.saved.emit(license);
            // this.submitted = false;
          });
      } else {
        this.submitted = false;
      }
    });
  }
}
