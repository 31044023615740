<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      User Downloads
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field class="filter">
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
      <mat-hint>Comma seperate filter terms. (Eg. application, username)</mat-hint>
    </mat-form-field>

    <mat-table [dataSource]="dataSource" matSort>

      <!-- Date Column -->
      <ng-container matColumnDef="logDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="mobile-label">Date:</span>
          {{element.logDate | date:'medium'}}
        </mat-cell>
      </ng-container>

      <!-- IP Address Date Column -->
      <ng-container matColumnDef="ipAddress">
        <mat-header-cell *matHeaderCellDef mat-sort-header> IP Address </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="mobile-label">IP:</span>
          {{element.ipAddress}}
        </mat-cell>
      </ng-container>

      <!-- App Column -->
      <ng-container matColumnDef="applicationName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Application </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="mobile-label">Application:</span>
          {{element.applicationName}}
        </mat-cell>
      </ng-container>

      <!-- Details Column -->
      <ng-container matColumnDef="details">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Details </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="mobile-label">Details:</span>
          {{element.details}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>

    <mat-spinner *ngIf="isLoading"></mat-spinner>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </mat-card-content>
</mat-card>