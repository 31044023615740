import { Component, Inject, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { NotificationService } from '~core/core.module';
import { LicenseService } from '~core/data/license.service';
import { License } from '~core/models/license.model';
import { GenerateLicenseDialogComponent } from './generate-license/generate-license-dialog.component';

@Component({
  selector: 'idl-license-details-dialog',
  template: `
    <h2 mat-dialog-title>License Details</h2>
    <mat-dialog-content class="mat-typography">
      <idl-license-details [licenseId]="licenseID"></idl-license-details>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        *nbIsGranted="['delete', 'license']"
        class="dialog-button"
        mat-raised-button
        color="warn"
        (click)="onDelete()"
      >
        Delete
      </button>
      <button
        *nbIsGranted="['revoke', 'license']"
        [disabled]="license.revoked"
        class="dialog-button"
        mat-raised-button
        color="warn"
        (click)="onRevoke()"
      >
        Revoke
      </button>
      <button
        *nbIsGranted="['renew', 'license']"
        class="dialog-button"
        mat-raised-button
        color="accent"
        (click)="onRenew()"
      >
        Renew
      </button>
      <button
        class="dialog-button"
        mat-stroked-button
        color="accent"
        mat-dialog-close
        cdkFocusInitial
      >
        Close
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    '.mat-mdc-dialog-content { height: 100%!important;}',
    '.dialog-button { margin: 0 5px;}'
  ]
})
export class LicenseDetailsDialogComponent {
  @Input() licenseID: number;
  license: License;

  constructor(
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<LicenseDetailsDialogComponent>,
    private _licenseService: LicenseService,
    private readonly _notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialogData: License
  ) {
    if (dialogData) {
      this.license = dialogData;
      this.licenseID = dialogData.licenseRecordId;
    }
  }

  onRevoke() {
    if (confirm('Are you sure you want to revoke this license?')) {
      this._licenseService
        .delete(this.licenseID)
        .pipe(take(1))
        .subscribe(
          data => {
            this._notificationService.success('License revoked.');
            this._dialogRef.close(true);
          },
          error => {
            this._notificationService.error('Error revoking license.');
          }
        );
    }
  }

  onDelete() {
    if (confirm('Are you sure you want to delete this license?')) {
      this._licenseService
        .delete(this.licenseID, true)
        .pipe(take(1))
        .subscribe(
          data => {
            this._notificationService.success('License deleted.');
            this._dialogRef.close(true);
          },
          error => {
            this._notificationService.error('Error deleting license.');
          }
        );
    }
  }

  onRenew() {
    this._licenseService
      .get(this.licenseID)
      .pipe(take(1))
      .subscribe(license => {
        const dlg = this._dialog.open(GenerateLicenseDialogComponent, {
          maxWidth: '800px',
          data: {
            applicationId: license.applicationId,
            license: license
          }
        });

        dlg
          .afterClosed()
          .pipe(take(1))
          .subscribe((new_license: License) => {
            if (new_license) {
              this._licenseService
                .delete(this.licenseID)
                .pipe(take(1))
                .subscribe(
                  data => {
                    this._notificationService.success(
                      'Previous License revoked.'
                    );
                    this._dialogRef.close(true);
                  },
                  error => {
                    this._notificationService.error(
                      'Error revoking previous license.'
                    );
                  }
                );
            }
          });

        // this._dialogRef.close(true);
      });
  }
}
