import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NbRoleProvider } from '@nebular/security';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {
  constructor(
    // public auth: NbAuthService,
    public router: Router,
    private roleProvider: NbRoleProvider
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    // this will be passed from the route config
    // on the data property
    const expectedRoles = route.data.expectedRoles;
    let roleExists = false;

    return this.roleProvider.getRole().pipe(
      filter(v => v !== null),
      map(response => {
        // orderExists will be true if there are any with whateverOrderId you want to check for
        if (response) {
          // orderExists will be true if there are any with whateverOrderId you want to check for
          expectedRoles.forEach(function(role) {
            roleExists = roleExists || response.includes(role);
          });

          if (!roleExists) {
            return this.router.parseUrl('');
          } else {
            return true;
          }
        }
      })
    );
  }
}
