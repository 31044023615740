const packageJson = require('../../package.json');
import { VERSION } from './version';

export const environment = {
  appName: 'InterDynamics Licensing',
  envName: 'PROD',
  production: true,
  test: false,
  i18nPrefix: '',
  versions: {
    app: VERSION.semverString,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome:
      packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress']
  },
  auth: {
    clientId: '1idXRYr3FUaQRp7HtiaJI72y3btlXnv8',
    domain: 'interdynamics.au.auth0.com', // e.g., you.auth0.com
    authorizationParams: {
      scope: 'openid profile email',
      redirect_uri: `${window.location.origin}/dashboard`,
      audience: 'https://licenseapi.azurewebsites.net'
    },
    useRefreshTokens: true
  },
  httpInterceptor: {
    allowedList: ['https://licenseapi.azurewebsites.net/*']
  },
  apiUrl: 'https://licenseapi.azurewebsites.net'
};
