import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment as env } from '@app/../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { State } from '~core/auth/models/auth-state.model';
import { selectAuthState } from '~core/core.state';
import { LocalStorageService } from '~core/local-storage/local-storage.service';
import { LogoutPromptComponent } from '../../components/logout-prompt.component';
import * as fromAuth from '../actions/auth.actions';

export const AUTH_KEY = 'AUTH';

// TODO: Romove storage service stuff
@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.Login>(fromAuth.AuthActionTypes.Login),
        tap(action => {
          return this._auth.loginWithRedirect();
        })
      ),
    { dispatch: false }
  );

  loginComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromAuth.LoginComplete>(fromAuth.AuthActionTypes.LoginComplete),
      filter(action => action.loggedin), // Filter out actions where loggedin is false
      map(
        action =>
          new fromAuth.LoginSuccess({
            targetroute: action.targetroute,
            user: action.user,
            id: action.id,
            roles: action.roles
          })
      )
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.LoginSuccess>(fromAuth.AuthActionTypes.LoginSuccess),
        map(action => action.payload),
        withLatestFrom(this._store.pipe(select(selectAuthState))),
        tap(([payload, auth]) => {
          this._localStorageService.setItem('AUTH_KEY', auth);
          if (payload.targetroute) {
            this.router.navigate([payload.targetroute]);
          }
        })
      ),
    { dispatch: false }
  ); // Set dispatch to false to not dispatch further actions

  loginErrorRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.LoginFailure>(fromAuth.AuthActionTypes.LoginFailure),
        map(action => action.payload),
        tap((err: any) => {
          // console.log('Login Error redirect');
          if (err.error_description) {
            console.error(`Error: ${err.error_description}`);
          } else {
            console.error(`Error: ${JSON.stringify(err)}`);
          }
        })
      ),
    { dispatch: false }
  );

  checkLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.CheckLogin>(fromAuth.AuthActionTypes.CheckLogin),
        tap(() => {
          return this._auth.getAccessTokenSilently().pipe(
            map(token => {
              return new fromAuth.LoginSuccess(null);
            }),
            catchError(error => {
              return of(new fromAuth.LoginFailure({ error }));
            })
          );
        })
      ),
    { dispatch: false }
  );

  refreshComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromAuth.RefreshComplete>(
        fromAuth.AuthActionTypes.RefreshComplete
      ),
      filter(action => action.loggedin), // Filter out actions where loggedin is false
      map(
        action =>
          new fromAuth.LoginSuccess({
            targetroute: null, // Assuming null is explicitly set for no redirection
            user: action.user,
            id: action.id,
            roles: action.roles
          })
      )
    )
  );

  refreshLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.RefreshLogin>(fromAuth.AuthActionTypes.RefreshLogin),
        tap(() => {
          return this._auth.getAccessTokenSilently();
        })
      ),
    { dispatch: false }
  );

  logoutConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromAuth.Logout>(fromAuth.AuthActionTypes.Logout),
      exhaustMap(() =>
        this.dialogService
          .open(LogoutPromptComponent)
          .afterClosed()
          .pipe(
            map(confirmed => {
              if (confirmed) {
                return new fromAuth.LogoutConfirmed();
              } else {
                return new fromAuth.LogoutCancelled();
              }
            })
          )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.LogoutConfirmed>(
          fromAuth.AuthActionTypes.LogoutConfirmed
        ),
        withLatestFrom(this._store.pipe(select(selectAuthState))),
        tap(([payload, auth]) => {
          this._localStorageService.setItem(AUTH_KEY, auth);
          this._auth.logout({
            clientId: env.auth.clientId,
            logoutParams: {
              returnTo: `${window.location.origin}`
            }
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private _auth: AuthService,
    private _localStorageService: LocalStorageService,
    private router: Router,
    private _store: Store<State>,
    private dialogService: MatDialog
  ) {}
}
